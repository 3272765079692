@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

html {
  @apply text-black;
  @apply font-sans;
}

a {
  @apply transition-colors;
  @apply hover:text-primary;
  @apply active:text-primary-dark;
}
