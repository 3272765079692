@import"https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap";.container{width: 100%
}@media (min-width: 640px){.container{max-width: 640px
    }
}@media (min-width: 768px){.container{max-width: 768px
    }
}@media (min-width: 1024px){.container{max-width: 1024px
    }
}@media (min-width: 1280px){.container{max-width: 1280px
    }
}@media (min-width: 1536px){.container{max-width: 1536px
    }
}.pointer-events-none{pointer-events: none
}.pointer-events-auto{pointer-events: auto
}.visible{visibility: visible
}.fixed{position: fixed
}.absolute{position: absolute
}.relative{position: relative
}.sticky{position: sticky
}.inset-0{inset: 0px
}.left-0{left: 0px
}.left-1\/2{left: 50%
}.right-4{right: 1rem
}.top-0{top: 0px
}.top-1{top: 0.25rem
}.top-\[27px\]{top: 27px
}.top-\[87px\]{top: 87px
}.top-full{top: 100%
}.z-10{z-index: 10
}.z-40{z-index: 40
}.z-\[1299\]{z-index: 1299
}.order-1{order: 1
}.order-2{order: 2
}.col-span-2{grid-column: span 2 / span 2
}.float-right{float: right
}.-m-\[1px\]{margin: -1px
}.m-0{margin: 0px
}.m-auto{margin: auto
}.mx-5{margin-left: 1.25rem;margin-right: 1.25rem
}.mx-\[20px\]{margin-left: 20px;margin-right: 20px
}.mx-auto{margin-left: auto;margin-right: auto
}.my-1{margin-top: 0.25rem;margin-bottom: 0.25rem
}.my-2{margin-top: 0.5rem;margin-bottom: 0.5rem
}.my-\[10px\]{margin-top: 10px;margin-bottom: 10px
}.-mb-1{margin-bottom: -0.25rem
}.mb-1{margin-bottom: 0.25rem
}.mb-10{margin-bottom: 2.5rem
}.mb-14{margin-bottom: 3.5rem
}.mb-16{margin-bottom: 4rem
}.mb-2{margin-bottom: 0.5rem
}.mb-3{margin-bottom: 0.75rem
}.mb-4{margin-bottom: 1rem
}.mb-5{margin-bottom: 1.25rem
}.mb-6{margin-bottom: 1.5rem
}.mb-7{margin-bottom: 1.75rem
}.mb-8{margin-bottom: 2rem
}.mb-\[100px\]{margin-bottom: 100px
}.ml-2{margin-left: 0.5rem
}.ml-3{margin-left: 0.75rem
}.ml-5{margin-left: 1.25rem
}.ml-auto{margin-left: auto
}.mr-1{margin-right: 0.25rem
}.mr-2{margin-right: 0.5rem
}.mr-3{margin-right: 0.75rem
}.mr-4{margin-right: 1rem
}.mr-5{margin-right: 1.25rem
}.mr-6{margin-right: 1.5rem
}.mr-7{margin-right: 1.75rem
}.mr-8{margin-right: 2rem
}.mt-1{margin-top: 0.25rem
}.mt-10{margin-top: 2.5rem
}.mt-16{margin-top: 4rem
}.mt-2{margin-top: 0.5rem
}.mt-3{margin-top: 0.75rem
}.mt-4{margin-top: 1rem
}.mt-5{margin-top: 1.25rem
}.mt-7{margin-top: 1.75rem
}.mt-auto{margin-top: auto
}.block{display: block
}.inline-block{display: inline-block
}.flex{display: flex
}.inline-flex{display: inline-flex
}.table{display: table
}.grid{display: grid
}.\!h-3{height: 0.75rem !important
}.\!h-3\.5{height: 0.875rem !important
}.\!h-\[40vh\]{height: 40vh !important
}.h-0{height: 0px
}.h-10{height: 2.5rem
}.h-12{height: 3rem
}.h-2{height: 0.5rem
}.h-20{height: 5rem
}.h-4\/5{height: 80%
}.h-5{height: 1.25rem
}.h-\[100px\]{height: 100px
}.h-\[140vh\]{height: 140vh
}.h-\[50px\]{height: 50px
}.h-\[65\%\]{height: 65%
}.h-\[calc\(100vh-87px\)\]{height: calc(100vh - 87px)
}.h-full{height: 100%
}.max-h-\[400px\]{max-height: 400px
}.min-h-0{min-height: 0px
}.min-h-\[20px\]{min-height: 20px
}.min-h-\[50px\]{min-height: 50px
}.min-h-\[80px\]{min-height: 80px
}.w-1\/2{width: 50%
}.w-1\/3{width: 33.333333%
}.w-1\/4{width: 25%
}.w-10{width: 2.5rem
}.w-12{width: 3rem
}.w-16{width: 4rem
}.w-20{width: 5rem
}.w-28{width: 7rem
}.w-3\/12{width: 25%
}.w-32{width: 8rem
}.w-4\/12{width: 33.333333%
}.w-4\/6{width: 66.666667%
}.w-48{width: 12rem
}.w-5{width: 1.25rem
}.w-5\/12{width: 41.666667%
}.w-52{width: 13rem
}.w-6{width: 1.5rem
}.w-6\/12{width: 50%
}.w-64{width: 16rem
}.w-7{width: 1.75rem
}.w-7\/12{width: 58.333333%
}.w-8\/12{width: 66.666667%
}.w-96{width: 24rem
}.w-\[100px\]{width: 100px
}.w-\[150px\]{width: 150px
}.w-\[1em\]{width: 1em
}.w-\[400px\]{width: 400px
}.w-\[50px\]{width: 50px
}.w-\[80px\]{width: 80px
}.w-\[86\.667\%\]{width: 86.667%
}.w-auto{width: auto
}.w-fit{width: -moz-fit-content;width: fit-content
}.w-full{width: 100%
}.\!min-w-\[40px\]{min-width: 40px !important
}.min-w-0{min-width: 0px
}.min-w-\[200px\]{min-width: 200px
}.min-w-\[32px\]{min-width: 32px
}.min-w-full{min-width: 100%
}.max-w-\[250px\]{max-width: 250px
}.max-w-full{max-width: 100%
}.max-w-lg{max-width: 32rem
}.max-w-md{max-width: 28rem
}.max-w-screen-sm{max-width: 640px
}.max-w-screen-xl{max-width: 1280px
}.max-w-sm{max-width: 24rem
}.flex-1{flex: 1 1 0%
}.shrink{flex-shrink: 1
}.shrink-0{flex-shrink: 0
}.flex-grow{flex-grow: 1
}.grow{flex-grow: 1
}.basis-10{flex-basis: 2.5rem
}.basis-\[100px\]{flex-basis: 100px
}.basis-\[700px\]{flex-basis: 700px
}.-translate-x-1\/2{--tw-translate-x: -50%;transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}.translate-y-0{--tw-translate-y: 0px;transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}.translate-y-0\.5{--tw-translate-y: 0.125rem;transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}.transform{transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}@keyframes spin{to{transform: rotate(360deg)
    }
}.animate-spin-fast{animation: spin 0.6s linear infinite
}.cursor-pointer{cursor: pointer
}.grid-cols-1{grid-template-columns: repeat(1, minmax(0, 1fr))
}.grid-cols-2{grid-template-columns: repeat(2, minmax(0, 1fr))
}.grid-cols-3{grid-template-columns: repeat(3, minmax(0, 1fr))
}.grid-cols-4{grid-template-columns: repeat(4, minmax(0, 1fr))
}.grid-cols-\[repeat\(auto-fill\2c minmax\(250px\2c 1fr\)\)\]{grid-template-columns: repeat(auto-fill,minmax(250px,1fr))
}.grid-cols-\[repeat\(auto-fit\2c minmax\(70px\2c 1fr\)\)\]{grid-template-columns: repeat(auto-fit,minmax(70px,1fr))
}.flex-row{flex-direction: row
}.flex-col{flex-direction: column
}.flex-wrap{flex-wrap: wrap
}.flex-nowrap{flex-wrap: nowrap
}.content-center{align-content: center
}.items-start{align-items: flex-start
}.items-center{align-items: center
}.justify-start{justify-content: flex-start
}.justify-end{justify-content: flex-end
}.justify-center{justify-content: center
}.justify-between{justify-content: space-between
}.gap-1{gap: 0.25rem
}.gap-2{gap: 0.5rem
}.gap-3{gap: 0.75rem
}.gap-4{gap: 1rem
}.gap-5{gap: 1.25rem
}.gap-6{gap: 1.5rem
}.gap-8{gap: 2rem
}.self-auto{align-self: auto
}.self-start{align-self: flex-start
}.self-center{align-self: center
}.self-stretch{align-self: stretch
}.justify-self-center{justify-self: center
}.overflow-auto{overflow: auto
}.overflow-hidden{overflow: hidden
}.overflow-scroll{overflow: scroll
}.whitespace-nowrap{white-space: nowrap
}.whitespace-pre-line{white-space: pre-line
}.rounded{border-radius: 0.25rem
}.rounded-2xl{border-radius: 1rem
}.rounded-full{border-radius: 9999px
}.rounded-lg{border-radius: 0.5rem
}.rounded-md{border-radius: 0.375rem
}.rounded-none{border-radius: 0px
}.border{border-width: 1px
}.border-0{border-width: 0px
}.border-2{border-width: 2px
}.border-\[1\.5px\]{border-width: 1.5px
}.border-b{border-bottom-width: 1px
}.border-b-3{border-bottom-width: 3px
}.border-t{border-top-width: 1px
}.border-t-3{border-top-width: 3px
}.border-solid{border-style: solid
}.border-\[\#DDDDDD\]{--tw-border-opacity: 1;border-color: rgb(221 221 221 / var(--tw-border-opacity))
}.border-gray-400{--tw-border-opacity: 1;border-color: rgb(156 163 175 / var(--tw-border-opacity))
}.border-transparent{border-color: transparent
}.border-b-primary{--tw-border-opacity: 1;border-bottom-color: rgb(24 184 191 / var(--tw-border-opacity))
}.\!bg-\[\#108085\]{--tw-bg-opacity: 1 !important;background-color: rgb(16 128 133 / var(--tw-bg-opacity)) !important
}.bg-\[\#108085\]{--tw-bg-opacity: 1;background-color: rgb(16 128 133 / var(--tw-bg-opacity))
}.bg-\[\#f0f0ef\]{--tw-bg-opacity: 1;background-color: rgb(240 240 239 / var(--tw-bg-opacity))
}.bg-gray-100{--tw-bg-opacity: 1;background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}.bg-primary{--tw-bg-opacity: 1;background-color: rgb(24 184 191 / var(--tw-bg-opacity))
}.bg-primary-dark{--tw-bg-opacity: 1;background-color: rgb(16 128 133 / var(--tw-bg-opacity))
}.bg-transparent{background-color: transparent
}.bg-white{--tw-bg-opacity: 1;background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}.bg-yellow-500{--tw-bg-opacity: 1;background-color: rgb(234 179 8 / var(--tw-bg-opacity))
}.object-contain{-o-object-fit: contain;object-fit: contain
}.object-cover{-o-object-fit: cover;object-fit: cover
}.p-0{padding: 0px
}.p-2{padding: 0.5rem
}.p-3{padding: 0.75rem
}.p-4{padding: 1rem
}.p-5{padding: 1.25rem
}.p-6{padding: 1.5rem
}.p-8{padding: 2rem
}.px-3{padding-left: 0.75rem;padding-right: 0.75rem
}.px-4{padding-left: 1rem;padding-right: 1rem
}.px-5{padding-left: 1.25rem;padding-right: 1.25rem
}.px-8{padding-left: 2rem;padding-right: 2rem
}.py-0{padding-top: 0px;padding-bottom: 0px
}.py-1{padding-top: 0.25rem;padding-bottom: 0.25rem
}.py-12{padding-top: 3rem;padding-bottom: 3rem
}.py-2{padding-top: 0.5rem;padding-bottom: 0.5rem
}.py-3{padding-top: 0.75rem;padding-bottom: 0.75rem
}.py-36{padding-top: 9rem;padding-bottom: 9rem
}.py-4{padding-top: 1rem;padding-bottom: 1rem
}.\!pb-0{padding-bottom: 0px !important
}.pb-0{padding-bottom: 0px
}.pb-2{padding-bottom: 0.5rem
}.pb-5{padding-bottom: 1.25rem
}.pb-\[100\%\]{padding-bottom: 100%
}.pb-\[66\.25\%\]{padding-bottom: 66.25%
}.pl-1{padding-left: 0.25rem
}.pl-2{padding-left: 0.5rem
}.pl-4{padding-left: 1rem
}.pr-0{padding-right: 0px
}.pr-4{padding-right: 1rem
}.pr-5{padding-right: 1.25rem
}.pr-8{padding-right: 2rem
}.pt-1{padding-top: 0.25rem
}.pt-10{padding-top: 2.5rem
}.pt-2{padding-top: 0.5rem
}.pt-5{padding-top: 1.25rem
}.pt-6{padding-top: 1.5rem
}.text-center{text-align: center
}.text-right{text-align: right
}.text-start{text-align: start
}.font-serif{font-family: DM Serif Display, ui-serif, Georgia, Cambria, "Times New Roman", Times, serif
}.text-2xl{font-size: 1.5rem;line-height: 2rem
}.text-3xl{font-size: 1.875rem;line-height: 2.25rem
}.text-4xl{font-size: 2.25rem;line-height: 2.5rem
}.text-5xl{font-size: 3rem;line-height: 1
}.text-6xl{font-size: 3.75rem;line-height: 1
}.text-7xl{font-size: 4.5rem;line-height: 1
}.text-8xl{font-size: 6rem;line-height: 1
}.text-\[10px\]{font-size: 10px
}.text-\[9px\]{font-size: 9px
}.text-base{font-size: 1rem;line-height: 1.5rem
}.text-lg{font-size: 1.125rem;line-height: 1.75rem
}.text-sm{font-size: 0.875rem;line-height: 1.25rem
}.text-xl{font-size: 1.25rem;line-height: 1.75rem
}.text-xs{font-size: 0.75rem;line-height: 1rem
}.font-bold{font-weight: 700
}.font-medium{font-weight: 500
}.font-normal{font-weight: 400
}.font-semibold{font-weight: 600
}.uppercase{text-transform: uppercase
}.lowercase{text-transform: lowercase
}.capitalize{text-transform: capitalize
}.normal-case{text-transform: none
}.italic{font-style: italic
}.leading-10{line-height: 2.5rem
}.leading-5{line-height: 1.25rem
}.leading-none{line-height: 1
}.leading-normal{line-height: 1.5
}.leading-tight{line-height: 1.25
}.text-\[\#108085\]{--tw-text-opacity: 1;color: rgb(16 128 133 / var(--tw-text-opacity))
}.text-\[\#18B8BF\]{--tw-text-opacity: 1;color: rgb(24 184 191 / var(--tw-text-opacity))
}.text-\[\#7C7C7C\]{--tw-text-opacity: 1;color: rgb(124 124 124 / var(--tw-text-opacity))
}.text-\[\#7c7c7c\]{--tw-text-opacity: 1;color: rgb(124 124 124 / var(--tw-text-opacity))
}.text-black{--tw-text-opacity: 1;color: rgb(51 51 51 / var(--tw-text-opacity))
}.text-blue-400{--tw-text-opacity: 1;color: rgb(96 165 250 / var(--tw-text-opacity))
}.text-danger{--tw-text-opacity: 1;color: rgb(244 67 54 / var(--tw-text-opacity))
}.text-gray-300{--tw-text-opacity: 1;color: rgb(209 213 219 / var(--tw-text-opacity))
}.text-gray-500{--tw-text-opacity: 1;color: rgb(107 114 128 / var(--tw-text-opacity))
}.text-neutral-400{--tw-text-opacity: 1;color: rgb(163 163 163 / var(--tw-text-opacity))
}.text-pink-500{--tw-text-opacity: 1;color: rgb(236 72 153 / var(--tw-text-opacity))
}.text-primary{--tw-text-opacity: 1;color: rgb(24 184 191 / var(--tw-text-opacity))
}.text-primary-dark{--tw-text-opacity: 1;color: rgb(16 128 133 / var(--tw-text-opacity))
}.text-slate-500{--tw-text-opacity: 1;color: rgb(100 116 139 / var(--tw-text-opacity))
}.text-slate-600{--tw-text-opacity: 1;color: rgb(71 85 105 / var(--tw-text-opacity))
}.text-success{--tw-text-opacity: 1;color: rgb(76 175 80 / var(--tw-text-opacity))
}.text-white{--tw-text-opacity: 1;color: rgb(255 255 255 / var(--tw-text-opacity))
}.underline{text-decoration-line: underline
}.line-through{text-decoration-line: line-through
}.shadow{--tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);--tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}.shadow-lg{--tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);--tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}.shadow-md{--tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);--tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}.shadow-none{--tw-shadow: 0 0 #0000;--tw-shadow-colored: 0 0 #0000;box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}.outline-0{outline-width: 0px
}.drop-shadow-sm{--tw-drop-shadow: drop-shadow(0 1px 1px rgb(0 0 0 / 0.05));filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}.\!filter{filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important
}.filter{filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}.transition{transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);transition-duration: 150ms
}html{--tw-text-opacity: 1;color: rgb(51 51 51 / var(--tw-text-opacity));font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}a{transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);transition-duration: 150ms
}a:hover{--tw-text-opacity: 1;color: rgb(24 184 191 / var(--tw-text-opacity))
}a:active{--tw-text-opacity: 1;color: rgb(16 128 133 / var(--tw-text-opacity))
}.after\:block::after{content: var(--tw-content);display: block
}.after\:w-full::after{content: var(--tw-content);width: 100%
}.after\:pb-\[100\%\]::after{content: var(--tw-content);padding-bottom: 100%
}.after\:pb-\[56\.25\%\]::after{content: var(--tw-content);padding-bottom: 56.25%
}.after\:pb-\[75\%\]::after{content: var(--tw-content);padding-bottom: 75%
}.after\:content-\[\'\'\]::after{--tw-content: '';content: var(--tw-content)
}.last\:mb-0:last-child{margin-bottom: 0px
}.last\:mr-0:last-child{margin-right: 0px
}.hover\:border-gray-300:hover{--tw-border-opacity: 1;border-color: rgb(209 213 219 / var(--tw-border-opacity))
}.hover\:border-pink-400:hover{--tw-border-opacity: 1;border-color: rgb(244 114 182 / var(--tw-border-opacity))
}.hover\:bg-\[\#18B8BF\]:hover{--tw-bg-opacity: 1;background-color: rgb(24 184 191 / var(--tw-bg-opacity))
}.hover\:bg-pink-50:hover{--tw-bg-opacity: 1;background-color: rgb(253 242 248 / var(--tw-bg-opacity))
}.hover\:bg-transparent:hover{background-color: transparent
}.hover\:text-black:hover{--tw-text-opacity: 1;color: rgb(51 51 51 / var(--tw-text-opacity))
}.hover\:text-pink-500:hover{--tw-text-opacity: 1;color: rgb(236 72 153 / var(--tw-text-opacity))
}.hover\:text-primary:hover{--tw-text-opacity: 1;color: rgb(24 184 191 / var(--tw-text-opacity))
}.hover\:text-primary-dark:hover{--tw-text-opacity: 1;color: rgb(16 128 133 / var(--tw-text-opacity))
}.hover\:brightness-105:hover{--tw-brightness: brightness(1.05);filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}.group:hover .group-hover\:brightness-105{--tw-brightness: brightness(1.05);filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}@media (min-width: 1024px){.lg\:flex-row{flex-direction: row
    }
}
:root, :host {
  --fa-font-solid: normal 900 1em/1 'Font Awesome 6 Solid';
  --fa-font-regular: normal 400 1em/1 'Font Awesome 6 Regular';
  --fa-font-light: normal 300 1em/1 'Font Awesome 6 Light';
  --fa-font-thin: normal 100 1em/1 'Font Awesome 6 Thin';
  --fa-font-duotone: normal 900 1em/1 'Font Awesome 6 Duotone';
  --fa-font-sharp-solid: normal 900 1em/1 'Font Awesome 6 Sharp';
  --fa-font-sharp-regular: normal 400 1em/1 'Font Awesome 6 Sharp';
  --fa-font-sharp-light: normal 300 1em/1 'Font Awesome 6 Sharp';
  --fa-font-brands: normal 400 1em/1 'Font Awesome 6 Brands'; }

svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa {
  overflow: visible;
  box-sizing: content-box; }

.svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 1em;
  overflow: visible;
  vertical-align: -.125em; }
  .svg-inline--fa.fa-2xs {
    vertical-align: 0.1em; }
  .svg-inline--fa.fa-xs {
    vertical-align: 0em; }
  .svg-inline--fa.fa-sm {
    vertical-align: -0.07143em; }
  .svg-inline--fa.fa-lg {
    vertical-align: -0.2em; }
  .svg-inline--fa.fa-xl {
    vertical-align: -0.25em; }
  .svg-inline--fa.fa-2xl {
    vertical-align: -0.3125em; }
  .svg-inline--fa.fa-pull-left {
    margin-right: var(--fa-pull-margin, 0.3em);
    width: auto; }
  .svg-inline--fa.fa-pull-right {
    margin-left: var(--fa-pull-margin, 0.3em);
    width: auto; }
  .svg-inline--fa.fa-li {
    width: var(--fa-li-width, 2em);
    top: 0.25em; }
  .svg-inline--fa.fa-fw {
    width: var(--fa-fw-width, 1.25em); }

.fa-layers svg.svg-inline--fa {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0; }

.fa-layers-text, .fa-layers-counter {
  display: inline-block;
  position: absolute;
  text-align: center; }

.fa-layers {
  display: inline-block;
  height: 1em;
  position: relative;
  text-align: center;
  vertical-align: -.125em;
  width: 1em; }
  .fa-layers svg.svg-inline--fa {
    transform-origin: center center; }

.fa-layers-text {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center; }

.fa-layers-counter {
  background-color: var(--fa-counter-background-color, #ff253a);
  border-radius: var(--fa-counter-border-radius, 1em);
  box-sizing: border-box;
  color: var(--fa-inverse, #fff);
  line-height: var(--fa-counter-line-height, 1);
  max-width: var(--fa-counter-max-width, 5em);
  min-width: var(--fa-counter-min-width, 1.5em);
  overflow: hidden;
  padding: var(--fa-counter-padding, 0.25em 0.5em);
  right: var(--fa-right, 0);
  text-overflow: ellipsis;
  top: var(--fa-top, 0);
  transform: scale(var(--fa-counter-scale, 0.25));
  transform-origin: top right; }

.fa-layers-bottom-right {
  bottom: var(--fa-bottom, 0);
  right: var(--fa-right, 0);
  top: auto;
  transform: scale(var(--fa-layers-scale, 0.25));
  transform-origin: bottom right; }

.fa-layers-bottom-left {
  bottom: var(--fa-bottom, 0);
  left: var(--fa-left, 0);
  right: auto;
  top: auto;
  transform: scale(var(--fa-layers-scale, 0.25));
  transform-origin: bottom left; }

.fa-layers-top-right {
  top: var(--fa-top, 0);
  right: var(--fa-right, 0);
  transform: scale(var(--fa-layers-scale, 0.25));
  transform-origin: top right; }

.fa-layers-top-left {
  left: var(--fa-left, 0);
  right: auto;
  top: var(--fa-top, 0);
  transform: scale(var(--fa-layers-scale, 0.25));
  transform-origin: top left; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em; }

.fa-xs {
  font-size: 0.75em;
  line-height: 0.08333em;
  vertical-align: 0.125em; }

.fa-sm {
  font-size: 0.875em;
  line-height: 0.07143em;
  vertical-align: 0.05357em; }

.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em; }

.fa-xl {
  font-size: 1.5em;
  line-height: 0.04167em;
  vertical-align: -0.125em; }

.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  position: absolute;
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit; }

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, 0.08em);
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em); }

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, 0.3em); }

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, 0.3em); }

.fa-beat {
  animation-name: fa-beat;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out); }

.fa-bounce {
  animation-name: fa-bounce;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1)); }

.fa-fade {
  animation-name: fa-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }

.fa-beat-fade {
  animation-name: fa-beat-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }

.fa-flip {
  animation-name: fa-flip;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out); }

.fa-shake {
  animation-name: fa-shake;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear); }

.fa-spin {
  animation-name: fa-spin;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 2s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear); }

.fa-spin-reverse {
  --fa-animation-direction: reverse; }

.fa-pulse,
.fa-spin-pulse {
  animation-name: fa-spin;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, steps(8)); }

@media (prefers-reduced-motion: reduce) {
  .fa-beat,
  .fa-bounce,
  .fa-fade,
  .fa-beat-fade,
  .fa-flip,
  .fa-pulse,
  .fa-shake,
  .fa-spin,
  .fa-spin-pulse {
    animation-delay: -1ms;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    transition-delay: 0s;
    transition-duration: 0s; } }

@keyframes fa-beat {
  0%, 90% {
    transform: scale(1); }
  45% {
    transform: scale(var(--fa-beat-scale, 1.25)); } }

@keyframes fa-bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0); }
  30% {
    transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em)); }
  50% {
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em)); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4); } }

@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    transform: scale(1); }
  50% {
    opacity: 1;
    transform: scale(var(--fa-beat-fade-scale, 1.125)); } }

@keyframes fa-flip {
  50% {
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg)); } }

@keyframes fa-shake {
  0% {
    transform: rotate(-15deg); }
  4% {
    transform: rotate(15deg); }
  8%, 24% {
    transform: rotate(-18deg); }
  12%, 28% {
    transform: rotate(18deg); }
  16% {
    transform: rotate(-22deg); }
  20% {
    transform: rotate(22deg); }
  32% {
    transform: rotate(-12deg); }
  36% {
    transform: rotate(12deg); }
  40%, 100% {
    transform: rotate(0deg); } }

@keyframes fa-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.fa-rotate-90 {
  transform: rotate(90deg); }

.fa-rotate-180 {
  transform: rotate(180deg); }

.fa-rotate-270 {
  transform: rotate(270deg); }

.fa-flip-horizontal {
  transform: scale(-1, 1); }

.fa-flip-vertical {
  transform: scale(1, -1); }

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1, -1); }

.fa-rotate-by {
  transform: rotate(var(--fa-rotate-angle, none)); }

.fa-stack {
  display: inline-block;
  vertical-align: middle;
  height: 2em;
  position: relative;
  width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: var(--fa-stack-z-index, auto); }

.svg-inline--fa.fa-stack-1x {
  height: 1em;
  width: 1.25em; }

.svg-inline--fa.fa-stack-2x {
  height: 2em;
  width: 2.5em; }

.fa-inverse {
  color: var(--fa-inverse, #fff); }

.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }

.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }

.svg-inline--fa .fa-primary {
  fill: var(--fa-primary-color, currentColor);
  opacity: var(--fa-primary-opacity, 1); }

.svg-inline--fa .fa-secondary {
  fill: var(--fa-secondary-color, currentColor);
  opacity: var(--fa-secondary-opacity, 0.4); }

.svg-inline--fa.fa-swap-opacity .fa-primary {
  opacity: var(--fa-secondary-opacity, 0.4); }

.svg-inline--fa.fa-swap-opacity .fa-secondary {
  opacity: var(--fa-primary-opacity, 1); }

.svg-inline--fa mask .fa-primary,
.svg-inline--fa mask .fa-secondary {
  fill: black; }

.fad.fa-inverse,
.fa-duotone.fa-inverse {
  color: var(--fa-inverse, #fff); }

